import React, { useContext } from 'react'

import AppContext from '../../components/App/context'
import InfoBox from '../../components/InfoBox'

import { getPostTypeConfig as getFilePostTypeConfig } from '../image'

import getConstants from './constants'
import Content from './Content'

function getPostTypeConfig() {
    const r = getFilePostTypeConfig({ name: 'document', getConstants })
    return {
        ...r,
        userPageFunctions: (p) => {
            return {
                ...r.userPageFunctions({
                    ...p,
                    nsPageProps: {
                        type: 'list',
                        BeforePostsComponent: () => {

                            const name = 'document'

                            const appContext = useContext(AppContext)

                            return (
                                <InfoBox
                                    title={appContext.labels[name + 'ListInfoTitle']}
                                    content={appContext.messages[name + 'ListInfoContent']}
                                />
                            )
                        }
                    }
                })
                //addMenuItems: () => []
            }
        },
        getComponent: (p) => {
            return r.getComponent({
                ...p, pages: {
                    content: Content
                }
            })
        }
    }
}

const postTypeConfig = getPostTypeConfig()

export default {
    ...postTypeConfig,
    userPageFunctions: (p) => {

        const documentUserPageFunctions = postTypeConfig.userPageFunctions({ ...p, name: 'document' })

        return {
            ...documentUserPageFunctions,
            addMenuItems: (props) => {

                const documentMenuItems = documentUserPageFunctions.addMenuItems(props)

                const { appContext } = props
                const { menus } = appContext

                return [
                    {
                        ...documentMenuItems[0].items[1],
                        role: (p) => {
                            const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            const isEditor = (p.user && p.user._isEditor)
                            const isAdmin = ((p.user && p.user._status_isFeatured))
                            if (!isAuthor || (!isEditor && isAdmin)) {
                                return false
                            }
                            return documentMenuItems[0].items[1].role(p)
                        },
                        order: 32
                    },
                    {
                        label: () => {
                            return menus['documents']
                        },
                        role: function(p) {
                            return ((p.user && p.user._status_isFeatured))
                        },
                        order: 32,
                        items: [
                            {
                                ...documentMenuItems[0].items[1],
                                role: (p) => {
                                    const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                    const isEditor = (p.user && p.user._isEditor)
                                    if (isEditor && !isAuthor) {
                                        return false
                                    }
                                    return documentMenuItems[0].items[1].role(p)
                                }
                            },
                            {
                                divider: true,
                                role: function(p) {
                                    return ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                                }
                            },
                            {
                                ...documentMenuItems[0].items[3],
                                role: function(p) {
                                    return p.user && p.user._status_isFeatured
                                }
                            }
                        ]
                    }
                ]
            }
        }
    }
}
