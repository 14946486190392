import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'language'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Nyelvek törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevancia',
            [ns + 'More']: 'Továbbiak'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Nyelvek',
            ['my' + Ns + 'Menu']: 'Nyelveim',
            ['new' + N + 'Menu']: 'Új nyelv',
            [ns + 'Menu']: 'Nyelvek',
            ['deleted' + Ns + 'Menu']: 'Törölt nyelvek',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt nyelvek',
            ['protection' + Ns + 'Menu']: 'Védett nyelvek',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Az új nyelv mentésre került',
            ['save' + N + 'SuccessMessage']: 'A nyelv mentésre került',
            ['delete' + N + 'Success']: 'A nyelv törölve lett',
            ['delete' + Ns + 'Success']: 'A nyelvek törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a nyelvet?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a nyelvekat?',
            ['ban' + N + 'Success']: 'A nyelv véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a nyelvet véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A nyelv jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a nyelvet',
            ['protection' + N + 'Success']: 'A nyelv védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a nyelvet?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek nyelvek'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Nyelv',
            [ns + 'Title']: 'Nyelvek',
            ['new' + N + 'Title']: 'Új nyelv',
            ['edit' + N + 'Title']: 'Nyelv szerkesztése',
            ['user' + Ns + 'Title']: 'Nyelvek',
            ['userDeleted' + Ns + 'Title']: 'Törölt nyelvek',
            ['my' + Ns + 'Title']: 'Nyelveim',
            ['myDeleted' + Ns + 'Title']: 'Törölt nyelveim',

            ['dialogDelete' + N + 'Title']: 'Nyelv törlése',
            ['dialogBan' + N + 'Title']: 'Nyelv törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Nyelv jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Nyelv védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Nyelv védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Nyelvek törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt nyelvek',
            ['deleted' + Ns + 'Title']: 'Törölt nyelvek',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett nyelvek',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Nyelvek kiválasztása',
            ['select' + N]: 'Nyelv kiválasztása'
        }
    }
}
