import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'chat'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Küldés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Küldés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Beszélgetések törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['moreFromMy' + Ns + 'Title']: 'További beszélgetéseim',
            ['moreFromUser' + Ns + 'Title']: 'További beszélgetések',
            [n + 'ContactBox']: 'További közösségi oldalak',
            [n + 'OpenContactBox']: 'Szerkesztés',

            [ns + 'SortRelevance']: 'Relevancia',
            [n + 'NewTryAgain']: 'Újra',

            [n + 'Connection']: 'Kapcsolódás',
            [n + 'SocketServerDisconnected']: 'A szerver kapcsolat bezárt',
            [n + 'SocketServerTryAgain']: 'Újra kapcsolódás'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'HR asszisztens',
            ['myPrevious' + Ns + 'Menu']: 'Korábbi beszélgetésim',
            ['userPrevious' + Ns + 'Menu']: 'Korábbi beszélgetések',
            ['new' + N + 'Menu']: 'Új beszélgetés',
            [ns + 'Menu']: 'Beszélgetések',
            ['deleted' + Ns + 'Menu']: 'Törölt beszélgetések',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt beszélgetések',
            ['protection' + Ns + 'Menu']: 'Védett beszélgetések',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',
            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Beszélgetésed rögzítettük',
            ['save' + N + 'SuccessMessage']: 'A beszélgetés mentésre került',
            ['delete' + N + 'Success']: 'A beszélgetés törölve lett',
            ['delete' + Ns + 'Success']: 'A beszélgetések törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az beszélgetést?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az beszélgetéseket?',
            ['ban' + N + 'Success']: 'A beszélgetés véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az beszélgetést véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A beszélgetés jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az beszélgetést',
            ['protection' + N + 'Success']: 'A beszélgetés védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd az beszélgetést?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek beszélgetések',

            [n + 'NewDefaultError']: 'Hiba történt a beszélgetés létrehozásakor, próbálja újra'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Beszélgetés',
            [ns + 'Title']: 'Beszélgetések',
            ['new' + N + 'Title']: 'Beszélgetés létrehozása',
            ['edit' + N + 'Title']: 'Beszélgetés szerkesztése',
            ['user' + Ns + 'Title']: 'Beszélgetések',
            ['userDeleted' + Ns + 'Title']: 'Törölt beszélgetések',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt beszélgetések',
            ['my' + Ns + 'Title']: 'Beszélgetéseim',
            ['myDeleted' + Ns + 'Title']: 'Törölt beszélgetéseim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt beszélgetéseim',

            ['dialogDelete' + N + 'Title']: 'Beszélgetés törlése',
            ['dialogBan' + N + 'Title']: 'Beszélgetés törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Beszélgetés jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Beszélgetés védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Beszélgetés védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Beszélgetések törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt beszélgetések',
            ['deleted' + Ns + 'Title']: 'Törölt beszélgetések',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett beszélgetések',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Beszélgetések kiválasztása',
            ['select' + N]: 'Beszélgetés kiválasztása'

        }
    }
}
