import React from 'react'

import capitalize from '../../utils/capitalize'

import defaultPostTypeConfig from '../post'

function getConstants(p = {}) {

    const name = 'message'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Üzenet mentése',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Üzenet mentése',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Üzenetek törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['moreFromMy' + Ns + 'Title']: 'További üzeneteim',
            ['moreFromUser' + Ns + 'Title']: 'További üzenetek',
            [n + 'ContactBox']: 'További közösségi oldalak',
            [n + 'OpenContactBox']: 'Szerkesztés',

            [ns + 'SortRelevance']: 'Relevancia'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Üzenetek',
            ['myPrevious' + Ns + 'Menu']: 'Korábbi üzeneteim',
            ['userPrevious' + Ns + 'Menu']: 'Korábbi üzenetek',
            ['new' + N + 'Menu']: 'Új üzenet',
            [ns + 'Menu']: 'Üzenetek',
            ['deleted' + Ns + 'Menu']: 'Törölt üzenetek',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt üzenetek',
            ['protection' + Ns + 'Menu']: 'Védett üzenetek',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',
            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Üzeneted rögzítettük',
            ['save' + N + 'SuccessMessage']: 'A üzenet mentésre került',
            ['delete' + N + 'Success']: 'A üzenet törölve lett',
            ['delete' + Ns + 'Success']: 'A üzenetek törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az üzenett?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az üzeneteket?',
            ['ban' + N + 'Success']: 'A üzenet véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az üzenett véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A üzenet jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az üzenett',
            ['protection' + N + 'Success']: 'A üzenet védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd az üzenett?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek üzenetek'

        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Üzenet',
            [ns + 'Title']: 'Üzenetek',
            ['new' + N + 'Title']: 'Üzenet létrehozása',
            ['edit' + N + 'Title']: 'Üzenet szerkesztése',
            ['user' + Ns + 'Title']: 'Üzenetek',
            ['userDeleted' + Ns + 'Title']: 'Törölt üzenetek',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt üzenetek',
            ['my' + Ns + 'Title']: 'Üzeneteim',
            ['myDeleted' + Ns + 'Title']: 'Törölt üzeneteim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt üzeneteim',

            ['dialogDelete' + N + 'Title']: 'Üzenet törlése',
            ['dialogBan' + N + 'Title']: 'Üzenet törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Üzenet jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Üzenet védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Üzenet védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Üzenetek törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt üzenetek',
            ['deleted' + Ns + 'Title']: 'Törölt üzenetek',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett üzenetek',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Üzenetek kiválasztása',
            ['select' + N]: 'Üzenet kiválasztása'

        }
    }
}


const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {},
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const { wapp } = p
        const name = 'message'

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name
        })

        wapp.contents.add({
            [name]: {
                ...wapp.contents.get(name),
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })
        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const { name = 'message' } = p

        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name
        })
    },
    getComponent: function(p) {

        const name = 'message'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const isAdmin = user && user._status_isFeatured

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            if (requestPath.startsWith(routes[name + 'Route']) && !isAdmin) {
                return null
            }

            if (requestPath.startsWith(routes[name + 'Route'] + '/new')) {
                return null
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                componentProps: {},
                archiveComponentProps: {
                    selectable: false,
                    type: 'list'
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context } = p

        const n = 'message'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'list',
                disableAvatars: true,
                ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        function addMenuItems() {
            return []
        }

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = user && user._status_isFeatured

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAdmin) ? page : 'notFound' : null

        }

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured

            if (page === ns && !isAdmin) {
                return null
            }

            return r.getDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName,
            getDashBoardTitle
        }
    },
    adminMenu: function() {
        return []
    }
}

export default postTypeConfig
