export function getConfig(p = {}) {

    const { config = {} } = p

    const commonConfig = config.common || {}
    const globalsConfig = config.globals || {}

    const { WAPP, DEV } = globalsConfig

    const common = {
        ...commonConfig,
        lang: 'hu_HU',
        siteName: 'Karrier Hub',
        description: 'A Karrier HUB egy innovatív álláskereső platform, ami HR asszisztens segítségével könnyen megtalálja a hozzád illő naprakész állásokat, legyen szó irodai munkáról, IT-ról, marketingről vagy szakmunkáról.',
        graphql: {
            route: (DEV) ? '/graphql' : '/g' + WAPP
        }
    }

    return {
        config: {
            ...config,
            common: common
        }
    }
}
