import { runPostTypesConfigSync } from '../../postTypes'

const routes = {
    accountRoute: '/account',
    updatePaymentRoute: '/stripe',
    payRoute: '/pay-now',
    szamlazzRoute: '/szamlazz',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'routes' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default routes
