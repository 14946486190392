import React from 'react'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'

import Menu from 'karrierhub-ui/dist/common/src/components/Menu'
import ChatIcon from 'karrierhub-ui/dist/common/src/svg/PencilIcon'
import AddIcon from 'karrierhub-ui/dist/common/src/svg/AddIcon'
import DashboardIcon from 'karrierhub-ui/dist/common/src/svg/DashboardIcon'

import capitalize from '../../utils/capitalize'
import { defaultGetPageName } from '../../components/Post/utils'

import defaultPostTypeConfig from '../post'
import getConstants from './constants'

import Content from './Content'
import Edit from './Edit'
import New from './New'
import getMenu from './menu'

function getAddMenuItems({ context, postContext, name }) {

    const n = name
    const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
    const N = capitalize(n)
    const Ns = capitalize(ns)

    return function addMenuItems(props) {

        const { appContext /*statusManager*/ } = props
        const { menus, routes /*userStatusManager*/ } = appContext

        return [
            {
                label: function() {
                    return menus[postContext.name + Ns + 'Menu']
                },
                role: function(p) {
                    const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                    const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                    return (isAdmin || isAuthor)
                },
                startIcon: <ChatIcon />,
                order: 34,
                items: [
                    {
                        label: menus['new' + N + 'Menu'],
                        href: routes[n + 'Route'] + '/new',
                        onClick: async (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            const utils = getUtils(context)
                            await utils.sendRequest({
                                requestName: name + 'New',
                                args: {
                                    record: {}
                                },
                                redirect: { pathname: routes[name + 'Route'] + '/:_id', search: '', hash: '' },
                                timeOut: 0
                            })
                        },
                        role: function(p) {
                            return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        },
                        disableParentRoute: true
                    },
                    {
                        divider: true,
                        role: function(p) {
                            return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        }
                    },
                    {
                        label: function(p) {
                            const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            return (isAuthor) ? menus['myPrevious' + Ns + 'Menu'] : menus['userPrevious' + Ns + 'Menu']
                        },
                        href: function(p) {
                            return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                        },
                        role: function(p) {
                            const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                            const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            return (isAdmin || isAuthor)
                        }
                    },
                    {
                        divider: true,
                        role: function(p) {
                            return ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                        }
                    },
                    {
                        label: function() {
                            return menus['deleted' + Ns + 'Menu']
                        },
                        href: function(p) {
                            return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                        },
                        role: function(p) {
                            return ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                        }
                    },
                    {
                        label: function() {
                            return menus['banned' + Ns + 'Menu']
                        },
                        href: function(p) {
                            return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/banned' : routes[postContext.name + Ns + 'Route'] + '/banned'
                        },
                        role: function(p) {
                            return p.user && p.user._status_isFeatured
                        }
                    }
                ]
            }
        ]
    }
}

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {},
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const { wapp } = p
        const name = 'chat'

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name
        })

        wapp.contents.add({
            [name]: {
                ComponentProps: {
                    TemplateProps: {
                        disableFooter: true
                    }
                },
                ...wapp.contents.get(name),
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })
        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const { name = 'chat' } = p

        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name
        })
    },
    getComponent: function(p) {

        const name = 'chat'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const pages = {
                content: Content,
                edit: Edit,
                new: New
            }

            const isAdmin = user && user._status_isFeatured

            const getPageName = ({ page, ...rest }) => {
                return defaultGetPageName({ page, ...rest })
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))
            const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !isAuthorOrAdmin) {
                return null
            }

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath === routes[name + 'Route'] + '/edit' && !isAdmin) {
                return null
            }

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !post?._status_isNotDeleted && !isAdmin) {
                return null
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                componentProps: {
                    getPageName,
                    showAuthor: () => false,
                    pages,
                    getMenu,
                    layoutType: 'sidebar',
                    getProps: (props) => {
                        if (props.pageName === 'content' || props.pageName === 'new' || props.pageName === 'edit') {

                            return {
                                getUserMenu: () => {

                                    const { menus } = appContext

                                    const addMenuItems = getAddMenuItems({
                                        context,
                                        postContext: { name: 'user' },
                                        name
                                    })
                                    const defaultMenuItems = addMenuItems({ appContext })

                                    return [
                                        {
                                            label: menus.dashboardMenu,
                                            href: function(p) {
                                                return (p.post?._id) ? '/' + p.post._id : '/'
                                            },
                                            role: function(p) {
                                                const isAdmin = p.user && p.user._status_isFeatured
                                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                                return (isAuthor || isAdmin)
                                            },
                                            startIcon: <DashboardIcon />
                                        },
                                        {
                                            divider: true,
                                            role: function(p) {
                                                const isAdmin = p.user && p.user._status_isFeatured
                                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                                return (isAuthor || isAdmin)
                                            }
                                        },
                                        ...defaultMenuItems[0] && defaultMenuItems[0].items?.length ? [
                                            ...defaultMenuItems[0].items.map((m, i) => {
                                                if (i === 0) {
                                                    return {
                                                        ...m,
                                                        startIcon: <AddIcon />
                                                    }
                                                }
                                                if (i === 2) {
                                                    return {
                                                        ...m,
                                                        startIcon: <ChatIcon />
                                                    }
                                                }
                                                return m
                                            })
                                        ] : []
                                    ]
                                },
                                sideBarSticky: true
                            }
                        }
                    },
                    TitleComponentProps: {
                        variant: 'h6'
                    }
                },
                archiveComponentProps: {
                    selectable: false,
                    type: 'list',
                    getMenu
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, postContext, appContext } = p

        const n = 'chat'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'list',
                disableAvatars: true,
                getMenu,
                ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        //const {userStatusManager} = appContext;

        const addMenuItems = getAddMenuItems({ context, postContext, name: n })

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured

            if (page === ns && pageType === 'deleted' && !isAdmin) {
                return null
            }

            return r.getDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName,
            getDashBoardTitle,
            getProps: (props) => {
                if (props.pageName === ns) {
                    return {
                        getMenu: () => {
                            return []
                        },
                        getUserMenu: () => {

                            const name = n

                            const { menus } = appContext

                            const addMenuItems = getAddMenuItems({ context, postContext: { name: 'user' }, name })
                            const defaultMenuItems = addMenuItems({ appContext })

                            return [
                                {
                                    label: menus.dashboardMenu,
                                    href: function(p) {
                                        return (p.post?._id) ? '/' + p.post._id : '/'
                                    },
                                    role: function(p) {
                                        const isAdmin = p.user && p.user._status_isFeatured
                                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                        return (isAuthor || isAdmin)
                                    },
                                    startIcon: <DashboardIcon />
                                },
                                {
                                    divider: true,
                                    role: function(p) {
                                        const isAdmin = p.user && p.user._status_isFeatured
                                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                        return (isAuthor || isAdmin)
                                    }
                                },
                                ...defaultMenuItems[0] && defaultMenuItems[0].items?.length ? [
                                    ...defaultMenuItems[0].items.map((m, i) => {
                                        if (i === 0) {
                                            return {
                                                ...m,
                                                startIcon: <AddIcon />
                                            }
                                        }
                                        if (i === 2) {
                                            return {
                                                ...m,
                                                startIcon: <ChatIcon />
                                            }
                                        }
                                        return m
                                    })
                                ] : []
                            ]
                        },
                        sideBarSticky: true
                    }
                }
            }
        }
    },
    adminMenu: function(p) {
        const name = 'chat'
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 30

        const { routes } = p.appContext

        defaultAdminMenu.items = defaultAdminMenu.items.filter((item) => {
            return item.href !== routes[name + 'Route'] + '/new'
        })

        return defaultAdminMenu
    }
}

export default postTypeConfig
