import React from 'react'

import MoneyIcon from 'karrierhub-ui/dist/common/src/svg/MoneyIcon'

import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'
import Content from './Content'
import { tableComponents, tableIcons } from './Content/tableComponents'

import New from './New'
import Edit from './Edit'
import getConstants from './constants'
import getMenu from './menu'

import Button from 'karrierhub-ui/dist/common/src/components/Button'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    orderId: { type: String }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const name = 'order'

        const { wapp } = p

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit'
            ]
        })

        const contentProps = wapp.contents.get(name)

        wapp.contents.add({
            [name]: {
                ...contentProps,
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })

        return r

    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const { res, name = 'order' } = p

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { _id, page, pageType } = params

        const isUnpaid = (page === ns && (pageType === 'unpaid') && _id)

        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: 'order',
            transformRequestParams: ({ requestParams }) => {
                // noinspection RedundantIfStatementJS
                if (isUnpaid) {
                    requestParams.args.filter.payed = false
                } else {
                    requestParams.args.filter.payed = true
                }
                return requestParams
            },
            ...isUnpaid ? {
                pageType: ''
            } : {}
        })
    },
    getPages: function() {
        return {
            content: Content,
            new: New,
            edit: Edit
        }
    },
    getComponent: function(p) {

        const name = 'order'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        const isAdmin = user && user._status_isFeatured

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const pages = {
                content: Content,
                new: New,
                edit: Edit
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))

            const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !isAuthorOrAdmin) {
                return null
            }

            const products = res.wappResponse.store.getState('res.responses.products')

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    type: 'table',
                    tableProps: {
                        components: tableComponents,
                        icons: tableIcons,
                        showLabelTitle: true
                    },
                    getMenu: (p) => getMenu(p).map((m) => {
                        delete m.featured
                        return m
                    }),
                    selectable: false
                },
                componentProps: {
                    pages,
                    layoutType: 'article',
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    showCreatedDate: ({ user, author }) => user?._id && user._id === author?._id,
                    getMenu,
                    getProps: ({ pageName }) => {
                        if (pageName === 'new') {
                            return {
                                pageProps: {
                                    CartProps: {
                                        ...products ? {
                                            products: {
                                                ...products,
                                                items: [
                                                    ...products.items ? products.items.filter((product) => {
                                                        return !product.enableQuantity
                                                    }) : []
                                                ]
                                            }
                                        } : {}
                                    }
                                }
                            }
                        }
                        if (pageName === 'content') {
                            return {
                                pageProps: {
                                    CartProps: {
                                        style: {
                                            display: 'none'
                                        }
                                    },
                                    SummaryProps: {
                                        style: {
                                            display: 'none'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, appContext, postContext } = p

        const n = 'order'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                tableProps: {
                    components: tableComponents,
                    icons: tableIcons,
                    showLabelTitle: true
                },
                getMenu,
                selectable: false,
                type: 'table',
                ListToolsProps: {
                    SearchProps: {
                        placeholder: appContext.labels[n + 'SearchLabel']
                    }
                }
            }
        })

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'MainMenu'] : menus[postContext.name + Ns + 'MainMenu']
                    },
                    role: function(p) {
                        const isAdmin = p.user && p.user._status_isFeatured
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return isAuthor || isAdmin
                    },
                    startIcon: <MoneyIcon />,
                    order: 25,
                    items: [
                        {
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(p) {
                                return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            },
                            disableParentRoute: true
                        },
                        {
                            divider: true
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                const isAdmin = p.user && p.user._status_isFeatured
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return isAuthor || isAdmin
                            }
                        },
                        {
                            label: function() {
                                return menus[postContext.name + Ns + 'UnpaidMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/unpaid' : routes[postContext.name + Ns + 'Route'] + '/unpaid'
                            },
                            role: function(p) {
                                const isAdmin = p.user && p.user._status_isFeatured
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return isAuthor || isAdmin
                            }
                        },
                        {
                            divider: true
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['myTransactionsMenu'] : menus[postContext.name + 'TransactionsMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + 'TransactionsRoute'] : routes[postContext.name + 'TransactionsRoute']
                            },
                            role: function(p) {
                                const isAdmin = p.user && p.user._status_isFeatured
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return isAuthor || isAdmin
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['myPaymentsMenu'] : menus[postContext.name + 'PaymentsMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + 'PaymentsRoute'] : routes[postContext.name + 'PaymentsRoute']
                            },
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        }
                    ]
                }
            ]
        }

        function getFeaturedActions(props) {

            const { appContext } = props

            return [
                {
                    label: appContext.menus['new' + N + 'Menu'],
                    href: appContext.routes[n + 'Route'] + '/new',
                    role: function(p) {
                        return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id)) && !p.page
                    },
                    startIcon: <MoneyIcon />,
                    disableParentRoute: true,
                    featured: true,
                    featuredMenuItemProps: {
                        color: 'primary',
                        variant: 'contained',
                        Component: Button,
                        size: 'default'
                    }
                }
            ]
        }

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'unpaid' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            if (page === ns && pageType === 'unpaid' && isAuthorOrAdmin) {
                return appContext.titles[postContext.name + Ns + 'UnpaidTitle']
            }

            return r.getDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            addMenuItems,
            getFeaturedActions,
            addContentMenuItems,
            getPageName,
            getDashBoardTitle
        }
    },
    adminMenu: function(p) {

        const name = 'order'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 40
        defaultAdminMenu.items = defaultAdminMenu.items.filter((item, i) => {
            return (i === 0 || i === 1 || i === 2 || i === 6 || i === 7)
        })
        return defaultAdminMenu
    }
}

export default postTypeConfig
