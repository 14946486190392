import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'qualification'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Végzettségek törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevancia',
            [ns + 'More']: 'Továbbiak'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Végzettségek',
            ['my' + Ns + 'Menu']: 'Végzettségeim',
            ['new' + N + 'Menu']: 'Új végzettség',
            [ns + 'Menu']: 'Végzettségek',
            ['deleted' + Ns + 'Menu']: 'Törölt végzettségek',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt végzettségek',
            ['protection' + Ns + 'Menu']: 'Védett végzettségek',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Az új végzettség mentésre került',
            ['save' + N + 'SuccessMessage']: 'A végzettség mentésre került',
            ['delete' + N + 'Success']: 'A végzettség törölve lett',
            ['delete' + Ns + 'Success']: 'A végzettségek törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a végzettséget?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a végzettségekat?',
            ['ban' + N + 'Success']: 'A végzettség véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a végzettséget véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A végzettség jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a végzettséget',
            ['protection' + N + 'Success']: 'A végzettség védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a végzettséget?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek végzettségek'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Végzettség',
            [ns + 'Title']: 'Végzettségek',
            ['new' + N + 'Title']: 'Új végzettség',
            ['edit' + N + 'Title']: 'Végzettség szerkesztése',
            ['user' + Ns + 'Title']: 'Végzettségek',
            ['userDeleted' + Ns + 'Title']: 'Törölt végzettségek',
            ['my' + Ns + 'Title']: 'Végzettségeim',
            ['myDeleted' + Ns + 'Title']: 'Törölt végzettségeim',

            ['dialogDelete' + N + 'Title']: 'Végzettség törlése',
            ['dialogBan' + N + 'Title']: 'Végzettség törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Végzettség jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Végzettség védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Végzettség védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Végzettségek törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt végzettségek',
            ['deleted' + Ns + 'Title']: 'Törölt végzettségek',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett végzettségek',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Végzettségek kiválasztása',
            ['select' + N]: 'Végzettség kiválasztása'
        }
    }
}
