import React from 'react'

import defaultPostTypeConfig from '../post'

import getConstants from './constants'
import capitalize from '../../utils/capitalize'

import { CustomMenuItem } from '../../components/Posts/Components/CustomMenuItem'
import { StartIconAvatar } from '../notification/StartIcon'

import New from './New'
import Edit from './Edit'

import { getMenu, getMenuProps } from './menu'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    secondaryUser: { type: Object }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const name = 'subscribe'

        const { wapp } = p

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit'
            ]
        })

        const contentProps = wapp.contents.get(name)

        wapp.contents.add({
            [name]: {
                ...contentProps,
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })

        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: 'subscribe'
        })
    },
    getComponent: function(p) {

        const name = 'subscribe'

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes } = appContext

        const { wapp, res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        const isAdmin = user && user._status_isFeatured

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const pages = {
                new: New,
                edit: Edit,
                content: Edit
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))

            const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !isAuthorOrAdmin) {
                return null
            }

            if (requestPath === routes[name + 'Route'] + '/new') {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: { pages, getPageName: () => 'notFound' }
                })
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    type: 'list',
                    tableProps: {
                        showLabelTitle: true
                    },
                    selectable: () => false,
                    selectFunctions: [],
                    MenuItemComponent: (props) => {

                        const thumb = props.itemProps.post?.thumb
                        const secondaryUser = props.itemProps.post?.secondaryUser
                        const editHref = props.itemProps.post?._id ? appContext.routes[name + 'Route'] + '/' + props.itemProps.post._id + '/edit' : null

                        return <CustomMenuItem
                            {...props}
                            {...!thumb && secondaryUser?._id ? {
                                StartIconContainerComponent: (props) => {
                                    return <StartIconAvatar {...props} secondaryUser={secondaryUser} />
                                },
                                startIcon: <></>
                            } : {}}
                            {...editHref ? { href: editHref } : {}}
                        />
                    },
                    getMenu,
                    getMenuProps: (p) => {
                        return getMenuProps({ ...p, wapp, res })
                    }
                },
                componentProps: {
                    pages,
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    getMenu,
                    getMenuProps: (p) => {
                        return getMenuProps({ ...p, wapp, res })
                    },
                    layoutType: 'sidebar'
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, appContext, postContext } = p

        const n = 'subscribe'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        //const N = capitalize(n)
        const Ns = capitalize(ns)

        const { wapp, res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                tableProps: {
                    showLabelTitle: true
                },
                selectable: () => false,
                selectFunctions: [],
                type: 'list',
                disablePageInfo: true,
                MenuItemComponent: (props) => {

                    const thumb = props.itemProps.post?.thumb
                    const secondaryUser = props.itemProps.post?.secondaryUser
                    const editHref = props.itemProps.post?._id ? appContext.routes[n + 'Route'] + '/' + props.itemProps.post._id + '/edit' : null

                    return <CustomMenuItem
                        {...props}
                        {...!thumb && secondaryUser?._id ? {
                            StartIconContainerComponent: (props) => {
                                return <StartIconAvatar {...props} secondaryUser={secondaryUser} />
                            },
                            startIcon: <></>
                        } : {}}
                        {...editHref ? { href: editHref } : {}}
                    />
                },
                getMenu,
                getMenuProps: (p) => {
                    return getMenuProps({ ...p, wapp, res })
                }
            }
        })

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                    },
                    role: function(p) {
                        return (p.user && p.user._status_isFeatured)
                    },
                    order: 33,
                    items: [
                        /*{
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(p) {
                                return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            },
                            disableParentRoute: true
                        },*/
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                const isAdmin = (p.user && p.user._status_isFeatured)
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return isAuthor || isAdmin
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        }
                    ]
                }
            ]
        }

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        const defaultGetDashBoardTitle = r.getDashBoardTitle

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured

            if (page === ns && pageType === 'deleted' && !isAdmin) {
                return null
            }

            return defaultGetDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            getDashBoardTitle,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function(p) {

        const name = 'subscribe'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 33

        defaultAdminMenu.items = defaultAdminMenu.items.filter((item, i) => {
            return (i === 0 || i === 1 || i === 2 || i === 3 || i === 6 || i === 7)
        })

        return defaultAdminMenu
    }
}

export default postTypeConfig
