import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { WappContext } from 'wapplr-react/dist/common/Wapp'

export function getKarrierHubUiFromCookieFunctions({ req, res, wapp }) {

    function setCookie(name, value, exDays) {
        const d = new Date()
        d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000))
        const expires = 'expires=' + d.toUTCString()
        document.cookie = name + '=' + value + ';' + expires + ';path=/'
    }

    function getCookie(cname) {
        let name = cname + '='
        let decodedCookie = decodeURIComponent(wapp.target === 'node' ? req.headers.cookie : document.cookie)
        let ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    }

    function setKarrierHubUiToCookie() {
        const themeData = res.wappResponse.store.getState('karrierhubUi') || {}
        setCookie('karrierhub-ui', JSON.stringify(themeData), 365)
    }

    function getKarrierHubUiFromCookie() {
        let data = getCookie('karrierhub-ui')
        try {
            data = JSON.parse(data)
        } catch (e) {

        }
        if (data?.theme) {
            const d = {}
            const enabledKeys = ['theme', 'mode', 'autoContrast', 'width']
            enabledKeys.forEach((key) => {
                const value = data[key]
                if (
                    (key === 'theme' && ['karrierhub', 'theme2'].indexOf(value) > -1) ||
                    (key === 'mode' && ['light', 'auto', 'dark'].indexOf(value) > -1) ||
                    (key === 'width' && ['wide', 'narrow'].indexOf(value) > -1) ||
                    (key === 'autoContrast' && [true, false].indexOf(value) > -1)
                ) {
                    d[key] = value
                }
            })
            return data
        }
        return {}
    }

    return {
        setKarrierHubUiToCookie,
        getKarrierHubUiFromCookie
    }

}

export function ComponentByWidth(props) {

    const context = useContext(WappContext)
    const { wapp, res } = context

    const {
        Component,
        ComponentDepWidth,
        InitialComponent
    } = props

    const [width, setWidth] = useState(props.width || res.wappResponse.store.getState('karrierhubUi.width'))
    const [isMounted, setMounted] = useState(props.isMounted || false)

    const effect = wapp.target === 'node' ? useEffect : useLayoutEffect

    const lastWidth = useRef(width)

    effect(() => {
        return res.wappResponse.store.subscribe(function({ type, payload }) {
            if (type === 'SET_KARRIERHUB_UI' && payload.name === 'width') {
                const nextState = res.wappResponse.store.getState('karrierhubUi')
                if (width !== nextState.width) {
                    setWidth(nextState.width)
                    lastWidth.current = nextState.width
                }
            }
        })
    }, [width])

    useEffect(() => {
        const nextState = res.wappResponse.store.getState('karrierhubUi')
        if (lastWidth.current !== nextState.width) {
            if (InitialComponent && !isMounted) {
                setMounted(true)
            }
            lastWidth.current = nextState.width
            setWidth(nextState.width)
        } else {
            if (InitialComponent && !isMounted) {
                setMounted(true)
            }
        }
    }, [width, isMounted])

    const Current = !isMounted && InitialComponent ? InitialComponent :
        ComponentDepWidth && ComponentDepWidth[width] ? ComponentDepWidth[width] : Component

    return Current ? <Current /> : null

}
