import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'
import priceWithSpaces from '../order/priceWithSpaces'

export default function getConstants(p = {}) {

    const name = 'job'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const draft = 'draft'
    const drafts = (draft.endsWith('y')) ? draft.slice(0, -1) + 'ies' : draft + 's'
    const Drafts = capitalize(drafts)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Álláshirdetések törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['moreFromMy' + Ns + 'Title']: 'További álláshirdetéseim',
            ['moreFromUser' + Ns + 'Title']: 'További álláshirdetések',
            ['more' + Ns + 'Label']: 'További álláshirdetések',

            [ns + 'SortRelevance']: 'Relevancia',

            [n + 'FacebookShare']: 'Facebook megosztás',
            [n + 'CopyUrl']: 'Link másolás',
            [n + 'SendViaEmail']: 'Elküldöm e-mailben',

            [n + 'SearchCityPlaceholder']: 'Település',
            [n + 'SearchTextPlaceholder']: 'Állás, kulcsszó, vállalat neve',
            [n + 'SearchSubmitLabel']: 'Keresés',
            [n + 'RefreshSubmitLabel']: 'Találatok frissítése',

            [n + 'PageInfoPerPage']: ({ startItem, endItem, allItem, perPage, currentPage }) => {
                if (currentPage >= 2 && (endItem - startItem <= perPage)) {
                    return `${currentPage}. oldal - ${allItem} álláshirdetés összesen`
                }
                if (allItem <= perPage || currentPage < 2) {
                    return `${allItem} álláshirdetés összesen`
                }
                return `${startItem} - ${endItem}, ${allItem} álláshirdetés összesen`
            },

            [n + 'InfiniteScrollLoadMoreLabel']: 'Többi álláshirdetés betöltése',
            [n + 'NewApplyCancelLabel']: 'Bezárom',
            [n + 'NewApplySubmitLabel']: 'Jelentkezem az állásra',

            [n + 'NewSubscribeCancelLabel']: 'Bezárom',
            [n + 'NewSubscribeSubmitLabel']: 'Feliratkozom',

            [n + 'NewNextToStep2Label']: 'Tovább',
            [n + 'EditNextToStep2Label']: 'Tovább',
            [n + 'EditNextToStep3Label']: 'Tovább',
            [n + 'EditNextToActivateLabel']: 'Tovább',
            [n + 'EditPrev']: 'Előző',

            [n + 'ShowButtonLabel']: 'Mutasd',
            [n + 'ShowButtonInactiveLabel']: 'Inaktív hirdetés',
            [n + 'OfAuthor']: ({ author }) => author?.title ? author?.title + ' álláshirdetései' : 'Felhasználó álláshirdetései',

            ['cancelNewOrderText']: 'Bezár',

            [n + 'ActivateCartLabel']: 'Kosár',
            [n + 'ActivateCartAvailableCredits']: 'Elérhető kreditjeid',
            [n + 'ActivateCartCreditsWithCurrency']: (credit) => priceWithSpaces(credit) + ' kredit',
            [n + 'ActivateCartCreditsCurrency']: 'kredit',
            [n + 'ActivateCartActiveIntervals']: 'Hirdetés feladása',
            [n + 'ActivateCartFeaturedDays']: 'Kiemelt napok',
            [n + 'ActivateCartRequiredCredits']: 'Szükséges kreditek vásárlása',
            [n + 'ActivateCartSummaCredits']: 'Összesen',
            [n + 'ActivateSubmitButtonLabelWithRequiredCredits']: (credit) => priceWithSpaces(credit) + ' kredit vásárlása és a hirdetés feladása',

            [n + 'ActivateCartPiecesWithQuantity']: (quantity) => quantity + ' db',
            [n + 'ActivateCartAvailableSubscriptionAds']: 'Elérhető előfizetéses hirdetések',
            [n + 'ActivateCartAvailableSubscriptionFeaturedDays']: 'Elérhető előfizetéses kiemelt napok',
            [n + 'ActivateCartSubscriptionNoChanges']: 'Nincs változás',
            [n + 'ActivateSubmitButtonLabelWithSubscription']: 'Hiredtés feladása előfizetéssel',

            [n + 'ActivationInfoTitle']: 'Álláshirdetés feladása, aktiválása, információk',
            [n + 'ActivateInfoCloseButton']: 'Bezár',

            [n + 'SuccessTitle']: 'Sikeres hirdetés feladás',
            [n + 'SuccessButtonNewAgain']: 'Új hirdetés feladása',
            [n + 'SuccessButtonViewJob']: 'Hirdetés megtekintése',

            [n + 'ActivateAddNextIntervalTitle']: 'Következő aktív időszak hozzáadása',

            [n + 'FeaturedDaysListTitle']: ({ selected }) => ['Kiválasztott napok', selected?.length ? ' (' + selected.length.toString() + ')' : ''].join(' '),
            [n + 'FeaturedDaysListDeleteAll']: 'Összes törlése',
            [n + 'FeaturedDaysAddDays']: ({ daysNumber }) => '+' + daysNumber + ' nap',
            [n + 'FeaturedDaysAddDaysTip']: ({ daysNumber }) => '' + daysNumber + ' nap kiemelés a mai naptól'

        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Álláshirdetések',
            ['user' + Ns + 'DraftsMenu']: 'Piszkozatok',
            ['user' + Ns + 'InactiveMenu']: 'Inaktív álláshirdetések',
            ['my' + Ns + 'Menu']: 'Álláshirdetéseim',
            ['new' + N + 'Menu']: 'Új álláshirdetés',
            [ns + 'Menu']: 'Álláshirdetések',
            [ns + 'LowView']: 'Kevés megtekintés és aktív',
            ['deleted' + Ns + 'Menu']: 'Törölt álláshirdetések',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt álláshirdetések',
            ['protection' + Ns + 'Menu']: 'Védett álláshirdetések',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            [drafts + 'Menu']: 'Piszkozatok',
            ['deleted' + Drafts + 'Menu']: 'Törölt piszkozatok',
            ['banned' + Drafts + 'Menu']: 'Véglegesen törölt piszkozatok',
            ['protection' + Drafts + 'Menu']: 'Védett piszkozatok',
            ['missingData' + Drafts + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Drafts + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése',

            ['all' + Ns + 'Menu']: 'Összes álláshirdetés',
            [n + 'SzakacsokProfessionAll']: 'Összes szakács állás',
            [n + 'KiszolgalokProfessionAll']: 'Összes kiszolgáló állás',
            [n + 'KisegitokProfessionAll']: 'Összes kisegítő állás',
            [n + 'VezetokProfessionAll']: 'Összes vezető állás',
            [n + 'SzallitokProfessionAll']: 'Összes szállító állás',
            [n + 'SzervezokProfessionAll']: 'Összes szervező állás',
            [n + 'Tovabbi-szakemberekProfessionAll']: 'Összes további állás',

            ['get' + N + 'ProfessionAll']: ({ profession }) => profession?.subtitle ? profession.subtitle + ' állás' : '',

            [n + 'SaveStep1']: 'Pozició',
            [n + 'SaveStep2']: 'Adatok',
            [n + 'SaveStep3']: 'Jelölt',
            [n + 'SaveActivate']: 'Hirdetés feladása',

            [n + 'ListActionRequiredData']: 'SZERKESZTÉS BEFEJEZÉSE',
            [n + 'ListActionActivate']: 'HIRDETÉS FELADÁSA',
            [n + 'ListActionReActivate']: 'AKTIVÁLÁS',
            [n + 'ListActionEditFeatured']: 'KIEMELÉS',
            [n + 'ListActionAddFeatured']: 'KIEMELÉS'

        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Módosításait piszkozatba mentettük',
            ['save' + N + 'SuccessMessage']: 'A álláshirdetés mentésre került',
            ['save' + N + 'DraftSuccessMessage']: 'Módosításait piszkozatba mentettük',
            ['delete' + N + 'Success']: 'A álláshirdetés törölve lett',
            ['delete' + Ns + 'Success']: 'A álláshirdetések törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az álláshirdetést?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az álláshirdetésekat?',
            ['ban' + N + 'Success']: 'A álláshirdetés véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az álláshirdetést véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A álláshirdetés jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az álláshirdetést',
            ['protection' + N + 'Success']: 'A álláshirdetés védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd az álláshirdetést?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek álláshirdetések',
            [n + 'CopyClipboardSuccess']: 'Hivatkozás másolva a vágólapra',
            [n + 'CopyClipboardError']: 'Ezen az eszközön nem támogatott a vágólapra másolás',
            [n + 'RequestFullDataError']: 'Nem sikerült az adatok lekérdezése, frissítse az oldalt',
            [n + 'SuccessMessage']: 'Hirdetésed sikeresen felkerült a Karrier Hub weboldalra. Már csak várd a jelentkezőket!',
            [n + 'ShouldAddNextInterval']: 'Kiemelni csak aktív időszakban lehet, válaszd ki a a következő aktív időszakot!',
            [n + 'ShouldAddNextIntervalFutureDay']: 'Kiemelni csak aktív időszakban lehet',
            [n + 'ShouldAddNextIntervalPastDay']: 'Nem lehet kiemelni olyan napot, ami már elmúlt',
            [n + 'NotFoundTypeText']: 'a hirdetés',
            [n + 'ActivationInfoContent']: 'Fizetés előtt olvasd el a tájékoztatót!',
            [n + 'ValidationActiveIntervalsNeedToSelectOne']: 'Válaszd ki a következő aktív időszakot'
        },
        routes: {
            ...routes,
            ['draftsRoute']: '/drafts'
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Álláshirdetés',
            [ns + 'Title']: 'Álláshirdetések',
            ['new' + N + 'Title']: 'Új álláshirdetés',
            ['edit' + N + 'Title']: 'Álláshirdetés szerkesztése',
            ['activate' + N + 'Title']: 'Hirdetés feladása',
            ['user' + Ns + 'Title']: 'Álláshirdetések',
            ['user' + Ns + 'DraftsTitle']: 'Piszkozatok',
            ['user' + Ns + 'InactiveTitle']: 'Inaktív álláshirdetések',
            ['userDeleted' + Ns + 'Title']: 'Törölt álláshirdetések',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt álláshirdetések',
            ['my' + Ns + 'Title']: 'Álláshirdetéseim',
            ['myDeleted' + Ns + 'Title']: 'Törölt álláshirdetéseim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt álláshirdetéseim',

            ['dialogDelete' + N + 'Title']: 'Álláshirdetés törlése',
            ['dialogBan' + N + 'Title']: 'Álláshirdetés törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Álláshirdetés jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Álláshirdetés védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Álláshirdetés védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Álláshirdetések törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt álláshirdetések',
            ['deleted' + Ns + 'Title']: 'Törölt álláshirdetések',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett álláshirdetések',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Álláshirdetések kiválasztása',
            ['select' + N]: 'Álláshirdetés kiválasztása',

            [n + 'BasicData']: 'Álláshirdetés adatai',
            [n + 'Contact']: 'Álláshirdetés elérhetőségei',

            [n + 'SzakacsokProfessionTitle']: 'Szakács álláshirdetések',
            [n + 'KiszolgalokProfessionTitle']: 'Kiszolgáló álláshirdetések',
            [n + 'KisegitokProfessionTitle']: 'Kisegítő álláshirdetések',
            [n + 'VezetokProfessionTitle']: 'Vezető álláshirdetések',
            [n + 'SzallitokProfessionTitle']: 'Szallitó álláshirdetések',
            [n + 'SzervezokProfessionTitle']: 'Szervező álláshirdetések',
            [n + 'Tovabbi-szakemberekProfessionTitle']: 'További álláshirdetések',

            ['get' + N + 'ProfessionTitle']: ({ profession }) => (profession?.title || '') + ' álláshirdetések',
            ['getMore' + N + 'Profession']: ({ title }) => 'További ' + title.toLowerCase(),

            [n + 'NewApplyTitle']: 'Jelentkezem a hirdetésre',
            [n + 'NewSubscribeTitle']: 'Feliratkozás',

            ['get' + N + 'MainFilterTitle']: (mainFilter) => {
                if (mainFilter?.title === 'Fehér galléros') {
                    return 'fehér galléros '
                }
                if (mainFilter?.title === 'Kék galléros') {
                    return 'kék galléros '
                }
                if (mainFilter?.title === 'Budapest') {
                    return 'budapesti '
                }
            }

        }
    }
}
