import capitalize from './capitalize'

export default function getStatus(p = {}) {

    const { user, post, appContext, /*statusManager, */name = 'post' } = p
    const N = capitalize(name)

    const {/*userStatusManager, */titles } = appContext

    const isNotDeleted = post && post._status_isNotDeleted
    const isBanned = post && post._status_isBanned
    const isValidated = post && post._status_isValidated
    const isApproved = post && post._status_isApproved
    const isFeatured = post && post._status_isFeatured
    const author = post?._author?._id || post?._author
    const isAuthor = (user?._id && user?._id === author)
    const isAdmin = user && user._status_isFeatured
    const isAuthorOrAdmin = !!(isAuthor || isAdmin)
    const authorIsNotDeleted = post?._author_status_isNotDeleted

    if (isAuthorOrAdmin) {

        const s = (isBanned && isAdmin) ?
            titles['statusBanned' + N + 'Title'] :
            (!isNotDeleted) ?
                titles['statusDeleted' + N + 'Title'] :
                (!isValidated) ?
                    titles['statusMissingData' + N + 'Title'] :
                    (isFeatured && isAdmin) ?
                        titles['statusFeatured' + N + 'Title'] :
                        (isApproved && isAdmin) ?
                            titles['statusApproved' + N + 'Title'] :
                            (isAdmin) ? titles['statusCreated' + N + 'Title'] : null

        return ((!authorIsNotDeleted && isAdmin && post?._id !== author)) ? s + ' (' + titles['statusAuthorDeleted' + N + 'Title'] + ')' : s

    }

    return null
}
