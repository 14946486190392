import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const { name = 'document' } = p

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Önéletrajzok törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',

            [ns + 'SortRelevance']: 'Relevancia',

            ['cancelAbortUpload' + Ns + 'Text']: 'Mégsem',
            ['abortUpload' + Ns + 'Text']: 'Megszakítás',
            ['upload' + Ns + 'HelperText']: 'Kattints vagy húzd ide az önéletrajzokat',
            ['upload' + N + 'HelperText']: 'Kattints vagy húzd ide az önéletrajzot',
            ['download' + N]: 'Önéletrajz letöltése',

            [ns + 'OrSelectFromListOpenLabel']: 'Médiatár',
            [n + 'ListInfoTitle']: ''
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Önéletrajzok',
            ['my' + Ns + 'Menu']: 'Önéletrajzaim',
            ['new' + N + 'Menu']: 'Önéletrajzok feltöltése',
            [ns + 'Menu']: 'Önéletrajzok',
            ['deleted' + Ns + 'Menu']: 'Törölt önéletrajzok',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt önéletrajzok',
            ['protection' + Ns + 'Menu']: 'Védett önéletrajzok',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + Ns + 'SuccessMessage']: 'Önéletrajzok feltöltve',
            ['save' + N + 'SuccessMessage']: 'Az önéletrajz mentésre került',
            ['delete' + N + 'Success']: 'Az önéletrajz törölve lett',
            ['delete' + Ns + 'Success']: 'Az önéletrajzok törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az önéletrajzot?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az önéletrajzokat?',
            ['ban' + N + 'Success']: 'Az önéletrajz véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az önéletrajzot véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'Az önéletrajz jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az önéletrajzot',
            ['protection' + N + 'Success']: 'Az önéletrajz védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölödrajzot?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek önéletrajzok',

            ['abortUpload' + Ns + 'Question']: 'Megszkítod a feltöltést?',
            ['abortUpload' + Ns + 'Success']: 'Feltöltés megszakítva',
            ['new' + Ns + 'ClientErrorMessage']: 'Hiba történt az önéletrajz megnyitásakor, próbáld újratölteni az oldalt',
            [n + 'ListInfoContent']: 'Ebben a listában a már előzőleg feltöltött önéletrajzaidat láthatod, és átírhatod a fileok nevét. Ha új önéletrajzot töltesz fel, az bekerül ide a médiatárba, de a már elküldött jelentkezéseidben nem változik meg.'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Önéletrajz',
            [ns + 'Title']: 'Önéletrajzok',
            ['new' + N + 'Title']: 'Új önéletrajz',
            ['edit' + N + 'Title']: 'Önéletrajz szerkesztése',
            ['user' + Ns + 'Title']: 'Önéletrajzok',
            ['userDeleted' + Ns + 'Title']: 'Törölt önéletrajzok',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt önéletrajzok',
            ['my' + Ns + 'Title']: 'Önéletrajzaim',
            ['myDeleted' + Ns + 'Title']: 'Törölt önéletrajzaim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt önéletrajzaim',

            ['dialogDelete' + N + 'Title']: 'Önéletrajz törlése',
            ['dialogBan' + N + 'Title']: 'Önéletrajz törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Önéletrajz jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Önéletrajz védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Önéletrajz védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Önéletrajzok törlése',
            ['dialogAbortUpload' + Ns + 'Title']: 'Feltöltés megszakítása',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt önéletrajzok',
            ['deleted' + Ns + 'Title']: 'Törölt önéletrajzok',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett önéletrajzok',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Önéletrajzok kiválasztása',
            ['select' + N]: 'Önéletrajz kiválasztása'
        }
    }
}
