import getUserPostTypeConfig from './user'
import getImagePostTypeConfig from './image'
import getVideoPostTypeConfig from './video'
import getDocumentPostTypeConfig from './document'
import getProfessionPostTypeConfig from './profession'

import getSoftSkillPostTypeConfig from './softSkill'
import getQualificationsPostTypeConfig from './qualification'
import getLanguagePostTypeConfig from './language'

import getPostPostTypeConfig from './post'
import getPagePostTypeConfig from './page'
import getBannerPostTypeConfig from './banner'
import getEmailPostTypeConfig from './email'
import getJobPostTypeConfig from './job'
import getChatPostTypeConfig from './chat'
import getMessagePostTypeConfig from './chat/message'
import getApplyPostTypeConfig from './apply'
import getSubscribePostTypeConfig from './subscribe'

import getProductPostTypeConfig from './product'
import getMethodPostTypeConfig from './method'
import getOrderPostTypeConfig from './order'
import getPaymentPostTypeConfig from './payment'
import getTransactionPostTypeConfig from './transaction'
import getNotificationPostTypeConfig from './notification'
import getProfilePostTypeConfig from './profile'

export const postTypesConfig = {
    user: getUserPostTypeConfig,
    image: getImagePostTypeConfig,
    video: getVideoPostTypeConfig,
    document: getDocumentPostTypeConfig,
    profession: getProfessionPostTypeConfig,
    softSkill: getSoftSkillPostTypeConfig,
    qualifications: getQualificationsPostTypeConfig,
    language: getLanguagePostTypeConfig,
    post: getPostPostTypeConfig,
    page: getPagePostTypeConfig,
    banner: getBannerPostTypeConfig,
    email: getEmailPostTypeConfig,
    job: getJobPostTypeConfig,
    apply: getApplyPostTypeConfig,
    chat: getChatPostTypeConfig,
    message: getMessagePostTypeConfig,
    subscribe: getSubscribePostTypeConfig,

    product: getProductPostTypeConfig,
    method: getMethodPostTypeConfig,
    order: getOrderPostTypeConfig,
    payment: getPaymentPostTypeConfig,
    transaction: getTransactionPostTypeConfig,
    notification: getNotificationPostTypeConfig,
    profile: getProfilePostTypeConfig
}

export function runPostTypesConfigSync({ action, rKey, p = {} }) {
    const config = postTypesConfig
    return Object.keys(config).map(function(key) {
        const postTypeConfig = config[key]
        if (postTypeConfig[action]) {
            const r = postTypeConfig[action](p)
            return (rKey) ? r[rKey] : r
        }
        return null
    })
}

export async function runPostTypesConfig({ action, rKey, p = {} }) {
    const config = postTypesConfig
    return await Promise.all(
        Object.keys(config).map(async function(key) {
            const postType = config[key]
            if (postType[action]) {
                const r = await postType[action](p)
                return (rKey) ? r[rKey] : r
            }
            return null
        })
    )
}
