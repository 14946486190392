import React, { useContext } from 'react'

import JobIcon from 'karrierhub-ui/dist/common/src/svg/JobIcon'
import Button from 'karrierhub-ui/dist/common/src/components/Button'
import AddCircleIcon from 'karrierhub-ui/dist/common/src/svg/AddCircleIcon'
import StarIcon from 'karrierhub-ui/dist/common/src/svg/StarIcon'

import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'
import getConstants from './constants'

import Posts from '../page/Content/Posts'
import PostContext from '../../components/Post/context'

import App from '../../components/App'
import { getDefaultContent, getListContent, replaceContentForArchivePage } from '../post/contents'

import PostInDrawer from '../../components/Post/PostInDrawer'
import defaultGetMenu, { getMenuProps as defaultGetMenuProps } from '../../components/Post/menu'
import { defaultGetPageName } from '../../components/Post/utils'

import New from './New'
import Edit from './Edit'
import Activate from './Activate'
import Content from './Content'

import ListHeader from './ListHeader'

export function getProfession(props) {

    const { res } = props

    const wappResponse = res.wappResponse
    const route = wappResponse.route

    const { params } = route

    if (params.professions) {
        const professions = wappResponse.store.getState('res.responses.professions')
        return professions?.items.length ? professions.items.find((profession) => profession.slug === params.professions) : null
    }

    return null

}

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    position: { type: Object }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {

        const {
            wapp,
            name = 'job',
            routes,
            urlParams = [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit',
                '/professions/:professions',
                '/softSkills/:softSkills',
                '/qualifications/:qualifications',
                '/languages/:languages'
            ],
            ...rest
        } = p

        const n = name
        const N = capitalize(n)
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams
        })

        wapp.router.replace([
            {
                path: routes[name + 'Route'] + '/:_id/:page',
                contentName: name,
                action: async function action(p) {

                    const { req, res, ...rest } = p

                    const page = rest.params.page

                    let r = { ...rest }

                    if ((page === 'fullview' && rest.status === 200 && !req.wappRequest.user) ||
                        (page === 'apply' && rest.status === 200 && !req.wappRequest.user) ||
                        (page === 'subscribe' && rest.status === 200 && !req.wappRequest.user)) {

                        const loginUrl = routes.accountRoute + '/login?redirect=' + encodeURIComponent(rest.requestPath)

                        if (wapp.target === 'node') {

                            res.wappResponse.status(302)
                            res.writeHead(302, {
                                'Location': loginUrl
                            })
                            res.end()

                            return
                        } else {

                            r = await wapp.router.routeManager.resolve({
                                path: routes.accountRoute + '/login',
                                req,
                                res
                            })
                            if (r.status === 200) {
                                r.status = 302
                            }
                        }
                    }

                    return r
                }
            },
            {
                path: routes[name + 'Route'] + '/:_id/:page/:pageType',
                contentName: name,
                action: async function action(p) {

                    const { req, res, ...rest } = p

                    const page = rest.params.page

                    let r = { ...rest }

                    if ((page === 'fullview' && rest.status === 200 && !req.wappRequest.user) ||
                        (page === 'apply' && rest.status === 200 && !req.wappRequest.user) ||
                        (page === 'subscribe' && rest.status === 200 && !req.wappRequest.user)) {

                        const loginUrl = routes.accountRoute + '/login?redirect=' + encodeURIComponent(rest.requestPath)

                        if (wapp.target === 'node') {

                            res.wappResponse.status(302)
                            res.writeHead(302, {
                                'Location': loginUrl
                            })
                            res.end()

                            return
                        } else {

                            r = await wapp.router.routeManager.resolve({
                                path: routes.accountRoute + '/login',
                                req,
                                res
                            })
                            if (r.status === 200) {
                                r.status = 302
                            }
                        }
                    }

                    return r
                }
            }
        ])

        const listContent = getListContent({ wapp, name, routes, urlParams, App, ...rest })
        const postContent = getDefaultContent({ wapp, name, routes, urlParams, App, ...rest })

        function _transformRequestParams({ requestParams }) {
            return requestParams
        }

        async function _request(props) {

            const {
                wapp,
                req,
                res,
                disableCache,
                transformRequestParams = _transformRequestParams,
                params = res.wappResponse.route.params,
                query = req.wappRequest.query,
                url = req.url
            } = props

            const wappResponse = res.wappResponse

            const postStatusManager = wapp.getTargetObject().postTypes.findPostType({ name }).statusManager
            const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager

            const listData = wappResponse.store.getState('res.graphql.query.' + name + 'FindMany.listData') || {}
            const listDataSort = listData.sort || []

            /*

              word search for ads:

                - accented characters and capital letters do not count
                - it only searches for words, not word parts, there is an OR relationship between the words
                - the most important in the row arrangement is [profession, business name, address of work], then comes the title and content

              there is an AND relationship between the city and the word search

              city finder for ads:

                - accented characters and capital letters do not count
                - it only searches for words, not word parts, there is an OR relationship between the words
                - relevancy of this does not matter when sorting, if it is used together with word search, it only filters its results

            * */

            if (query.search) {
                listDataSort.push({ key: 'TEXTSCORE' })
            }

            const defaultSort = listDataSort[0]?.key || ''

            let sort = (params.sort && listDataSort && listDataSort.map((p) => p.key).find((key) => key === params.sort)) || defaultSort

            if (sort === 'TEXTSCORE') {
                sort = ''
            }

            const page = params.page
            const pagination = !isNaN(Number(params.pagination)) && Number(params.pagination) ? Number(params.pagination) : 1

            const perPageFormData = listData.perPage
            const limitPerPage = perPageFormData.limit || 100
            const defaultPerPage = perPageFormData.default || 20
            const perPage = (params.limit && !isNaN(Number(params.limit)) && Number(params.limit) <= limitPerPage && Number(params.limit) > 1) ? Number(params.limit) : defaultPerPage

            const formData = wappResponse.store.getState('res.graphql.query.' + name + 'FindMany.formData')

            const latinGroups = [
                ['a', 'á'],
                ['e', 'é'],
                ['i', 'í'],
                ['i', 'í'],
                ['o', 'ó', 'ö', 'ő'],
                ['u', 'ú', 'ü', 'ű']
            ]

            const cityString = query.city ?
                query.city
                    .split(' ')
                    .filter((t) => t)
                    .map((t) => {
                        return t.split('').reduce((t, char) => {
                            const group = latinGroups.find((g) => g.indexOf(char) > -1)
                            if (group) {
                                t = t + `[${group.join('|')}]`
                            } else {
                                t = t + char
                            }
                            return t
                        }, '')
                    })
                    .join('|')
                : ''

            const filter = urlParams.reduce((o, urlParamText) => {
                if (!urlParamText.startsWith('/page/:') && !urlParamText.startsWith('/sort/:') && !urlParamText.startsWith('/limit/:')) {
                    const paramNameN = urlParamText.startsWith('/') ? 1 : 0
                    const paramName = urlParamText.split('/')[paramNameN]
                    if (params[paramName] && formData['filter.' + paramName]) {
                        const { schemaType, multiple } = formData['filter.' + paramName]
                        let value = params[paramName]
                        if (multiple) {
                            try {
                                value = (value.startsWith('[') && value.endsWith(']')) ? JSON.parse(value) : value.split(',')
                            } catch (e) {
                            }
                        } else {
                            if (schemaType === 'Float' || schemaType === 'Int') {
                                value = Number(value)
                            }
                        }
                        if (typeof value !== 'undefined') {
                            o[paramName] = value
                        }
                    }
                }
                return o
            }, {})

            const authorStatus =
                (page === 'lowauthorstatus') ?
                    {
                        _author_status: {
                            lt: userStatusManager.getMinStatus()
                        }
                    } :
                    (page === 'banned' || page === 'deleted' || page === 'missingdata' || page === 'protected') ? {
                            _author_status: {
                                gt: userStatusManager.getBannedStatus() - 1
                            }
                        } :
                        {
                            _author_status: {
                                gt: userStatusManager.getMinStatus() - 1
                            }
                        }

            const status =
                (page === 'banned') ?
                    { gt: postStatusManager.getBannedStatus() - 1, lt: postStatusManager.getDeletedStatus() } :
                    (page === 'deleted') ?
                        { gt: postStatusManager.getDeletedStatus() - 1, lt: postStatusManager.getDefaultStatus() } :
                        (page === 'missingdata') ?
                            { gt: postStatusManager.getDefaultStatus() - 1, lt: postStatusManager.getMinStatus() } :
                            (page === 'protected') ?
                                { gt: postStatusManager.getFeaturedStatus() - 1 } :
                                (page === 'lowauthorstatus') ?
                                    { gt: postStatusManager.getBannedStatus() - 1 } :
                                    { gt: postStatusManager.getMinStatus() - 1 }

            const requestParams = transformRequestParams({
                requestParams: {
                    requestName: name + 'FindMany',
                    args: {
                        filter: {
                            ...(query.search) ? { search: query.search } : {},
                            ...(Object.keys(filter).length) ? filter : {},
                            _operators: {
                                _status: status,
                                ...authorStatus,
                                ...(cityString) ? {
                                    city: {
                                        regex: `/(^|[\s"])(${cityString})(?![^\s"])/gi`
                                    }
                                } : {},
                                _jobStatus: {
                                    gte: 0
                                },
                                ...page === 'lowview' ? {
                                    _views: { lt: 100 },
                                    _jobStatus: {
                                        gte: 1
                                    }
                                } : {}
                            }
                        },
                        ...(sort) ? { sort } : {},
                        page: pagination,
                        perPage
                    }
                }
            })

            const storageKey = name + '-posts-' + url + '-' + JSON.stringify(requestParams)

            if (wapp.target === 'web' && req.history?.action === 'POP' && !disableCache) {
                const response = res.wappResponse.store.getState('res.cache.' + storageKey)
                if (response) {
                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: 'INS_RES',
                        name: 'responses',
                        value: { [requestParams.requestName]: response }
                    }))
                    return {
                        [requestParams.requestName]: response
                    }
                }
            }

            const response = await wapp.requests.send({ ...requestParams, req, res })

            if (wapp.target === 'web' && response && response[requestParams.requestName]) {
                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'INS_RES',
                    name: 'cache',
                    value: { [storageKey]: response[requestParams.requestName] }
                }))
            }

            return response

        }

        function getPost(res) {
            const wappResponse = res.wappResponse
            const route = wappResponse.route
            const { params } = route
            const { _id } = params

            const posts = [
                wappResponse.store.getState('res.responses.' + name + 'FindById')
            ]

            return posts.find((p) => p?._id === _id)
        }

        wapp.contents.add({
            [n]: {
                ...postContent,
                ComponentProps: {
                    TemplateProps: {
                        BeforeChildren: undefined, //PostHeader,
                        disableFooter: true
                    }
                },
                ldJson: (context) => {

                    const { res, req } = context

                    try {
                        const post = getPost(res)

                        if (post?._id) {

                            const date = new Date(post.createdDateText)
                            const validThrough = new Date()

                            validThrough.setDate(date.getDate() + 30)

                            return JSON.stringify({
                                '@context': 'https://schema.org/',
                                '@type': 'JobPosting',
                                'title': post.title,
                                'image': [
                                    ...post.gallery ? post.gallery.map((image) => image.thumb) : (post.cover?.thumb) ? [post.cover?.thumb] : post.thumb ? [post.thumb] : []
                                ].map((url) => {
                                    return req.wappRequest.protocol + '://' + req.wappRequest.hostname + url
                                }),
                                'datePosted': date,
                                'validThrough': validThrough,
                                'description': post.content,
                                'hiringOrganization': {
                                    '@type': 'Organization',
                                    'name': post._author?.title
                                },
                                'employmentType': JSON.stringify(post.employmentType),
                                'jobLocation': {
                                    '@type': 'Place',
                                    'address': {
                                        '@type': 'PostalAddress',
                                        'addressLocality': post.city,
                                        'postalCode': post.postCode,
                                        'addressCountry': 'HU'
                                    }
                                }
                            })

                        }
                    } catch (e) {
                        console.log('[APP] Can\'t include ld+json for a job', req.url, e)
                    }

                    return null
                }
            },
            [ns]: {
                ...listContent,
                ComponentProps: {
                    TemplateProps: {
                        BeforeChildren: ListHeader,
                        BeforeChildrenProps: {
                            parentRoute: ({ res }) => {

                                const route = res.wappResponse.route
                                const { params } = route
                                const { page } = params

                                return routes[ns + 'Route'] + (page ? '/' + page : '')
                            },
                            defaultTitle: ({ wapp, req, res }) => {
                                const title = typeof listContent.title === 'function' ? listContent.title({
                                    wapp,
                                    req,
                                    res
                                }) : listContent.title
                                return title ? title.split(' | ')[0] : undefined
                            }
                        }
                    }
                },
                title: (context) => {

                    const profession = getProfession(context)
                    if (profession?.title) {

                        const title = (
                            rest.titles[name + capitalize(profession.slug) + 'ProfessionTitle'] ||
                            (rest.titles['get' + N + 'ProfessionTitle'] ? rest.titles['get' + N + 'ProfessionTitle']({ profession }) : '') ||
                            profession.title
                        )

                        return rest.getTitle ? rest.getTitle({ ...context, title }) : title
                    }

                    const defaultTitleFunction = listContent.title
                    return defaultTitleFunction(context)
                },
                image: (context) => {

                    const profession = getProfession(context)

                    if (profession?.title) {

                        let thumb = profession.thumb || null

                        if (!thumb) {
                            if (profession.parentProfessions?.length) {
                                const found = profession.parentProfessions.find((profession) => {
                                    return profession?.thumb
                                })
                                thumb = found?.thumb || null
                            }
                        }

                        if (thumb && thumb.startsWith('/thumb')) {
                            thumb = thumb.replace('/thumb', '/thumb/2')
                        }

                        if (thumb) {

                            try {

                                const { req } = context
                                const hostname = req.wappRequest.hostname
                                const protocol = req.wappRequest.protocol
                                const url = new URL(protocol + '://' + hostname + thumb)
                                if (url.searchParams.get('crop')) {

                                    const crop = JSON.parse(url.searchParams.get('crop'))
                                    const width = crop.croppedAreaPixels.width
                                    const height = crop.croppedAreaPixels.height
                                    const maxDim = 800
                                    const scale = Math.min(maxDim / width, maxDim / height)

                                    return {
                                        width: Math.round(width * scale),
                                        height: Math.round(height * scale),
                                        thumb
                                    }
                                }

                            } catch (e) {

                            }

                        }

                        return thumb || undefined

                    }

                    return undefined

                },
                imageAlt: (context) => {

                    const profession = getProfession(context)
                    if (profession?.title) {
                        return profession.title
                    }

                    return undefined

                },
                request: async function(props) {
                    return await _request(props)
                }
            }
        })

        const draftName = 'draft'
        const drafts = (draftName.endsWith('y')) ? draftName.slice(0, -1) + 'ies' : draftName + 's'

        wapp.contents.add({
            [drafts]: {
                ...wapp.contents.get(ns),
                request: async function({ wapp, req, res }) {
                    await replaceContentForArchivePage({
                        wapp,
                        req,
                        res,
                        defaultPath: '/' + drafts,
                        replacePath: routes[ns + 'Route'],
                        transformRequestParams: ({ requestParams }) => {
                            requestParams.args.filter._operators._jobStatus = { lt: 0 }
                            return requestParams
                        }
                    })
                }
            }
        })

        wapp.router.add([
            { path: '/' + drafts + '/*', contentName: drafts }
        ])


        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const { res, name = 'job' } = p

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { _id, page, pageType } = params

        const isDraft = (page === ns && (pageType === 'drafts') && _id)
        const isInactive = (page === ns && (pageType === 'inactive') && _id)

        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name,
            transformRequestParams: ({ requestParams }) => {
                if (isDraft) {
                    requestParams.args.filter._operators._jobStatus = {
                        lt: 0
                    }
                } else if (isInactive) {
                    requestParams.args.filter._jobStatus = 0
                } else if (!pageType) {
                    requestParams.args.filter._operators._jobStatus = {
                        gt: 0
                    }
                }
                return requestParams
            },
            ...isDraft || isInactive ? {
                pageType: ''
            } : {}
        })
    },
    getComponent: function(p) {

        const name = 'job'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
        const N = capitalize(name)

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const { pageType } = route.params

        if (
            (requestPath.startsWith(routes[name + 'Route'])) ||
            (requestPath.startsWith(routes[ns + 'Route']))
        ) {

            function removeJobUrl(url = window.location.href) {
                try {
                    const fullUrl = new URL(url)
                    fullUrl.searchParams.delete(name)
                    return fullUrl.href.split(fullUrl.origin)[1]
                } catch (e) {
                }
            }

            function getJobUrl(post, url = window.location.href) {
                try {
                    const fullUrl = new URL(url)
                    fullUrl.searchParams.delete(name)
                    fullUrl.searchParams.append(name, post._id)
                    return fullUrl.href.split(fullUrl.origin)[1]
                } catch (e) {
                }
            }

            const postInDrawer = {}

            let startUrl

            let _onSubmit

            const pages = {
                new: New,
                edit: Edit,
                activate: Activate,
                content: Content
            }

            const defaultComponentProps = {
                pageProps: {
                    showAuthor: true,
                    showThumbnail: true,
                    GalleryProps: {
                        maxImage: 3
                    }
                },
                PaperComponentProps: {
                    /*color: undefined,
                    elevation: 0,
                    style: {
                        padding: 0
                    }*/
                },
                HeaderComponent: () => null
            }

            const user = req.wappRequest.user
            const isAdmin = (user && user._status_isFeatured) || (user && user._isEditor)

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)

            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))
            const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (requestPath.startsWith(routes[name + 'Route']) && post?._jobStatus < 0 && !isAuthorOrAdmin) {
                return null
            }

            req.wappRequest.requestPath = routes[ns + 'Route']

            const enablePostInDrawer = false

            const { device } = appContext
            const isMobile = (device.type === 'mobile' || device.type === 'tablet' || device.type === 'wearable')

            function beforeSetUser(user) {

                if (!user) {
                    return null
                }

                return {
                    ...user,
                    _status_isFeatured: user?._status_isFeatured || user?._isEditor
                }
            }

            function getMenuContext(type, context) {
                if (type === 'header') {
                    return {
                        ...context,
                        getPropsForMenuItemProps: (p) => {
                            const r = context.getPropsForMenuItemProps(p)
                            return {
                                ...r,
                                user: beforeSetUser(r.user)
                            }
                        }
                    }
                }
                return context
            }

            function getPostContext(context) {
                return {
                    ...context,
                    user: beforeSetUser(context.user)
                }
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    type: 'cardList',
                    selectable: false,
                    disablePageInfo: true,
                    infiniteScroll: !isMobile,
                    accessForEditor: true,
                    beforeSetUser,
                    ...enablePostInDrawer ? {
                        onClick: async (e, post) => {
                            if (postInDrawer.actions) {
                                e.preventDefault()
                                e.stopPropagation()
                                const newUrl = getJobUrl(post)
                                window.history.replaceState(window.history.state, undefined, newUrl)
                                await postInDrawer.actions.setPostId(post._id)
                            }
                        },
                        getMenuProps: (props) => {
                            _onSubmit = props.onSubmit
                            return defaultGetMenuProps(props)
                        },
                        BeforePostsComponent: () => {

                            let shouldUpdate = false

                            return (
                                <>
                                    <PostInDrawer
                                        name={name}
                                        effect={({ actions }) => {
                                            postInDrawer.actions = actions
                                        }}
                                        onOpen={async () => {
                                            startUrl = window.location.href
                                        }}
                                        beforeClose={() => {
                                            const newUrl = removeJobUrl(startUrl)
                                            window.history.replaceState(window.history.state, undefined, newUrl)
                                        }}
                                        onClose={async () => {
                                            if (shouldUpdate) {
                                                const _post = postInDrawer.actions?.getPost()
                                                if (_post?._id) {
                                                    const requestKeys = [name + 'FindById']
                                                    const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)

                                                    if (_onSubmit) {
                                                        await _onSubmit(null, post?._id ? post : _post, name + 'CloseDrawer')
                                                    }
                                                }
                                            }
                                        }}
                                        maxWidth={'640px'}
                                        PostTypesComponentProps={{
                                            getProps: () => {
                                                return {
                                                    ...defaultComponentProps,
                                                    pageProps: {
                                                        ...defaultComponentProps.pageProps,
                                                        disableReplaceState: true,
                                                        TitleComponentProps: {
                                                            variant: 'h4'
                                                        },
                                                        onChange: () => {
                                                            shouldUpdate = true
                                                        }
                                                    },
                                                    propsForGetMenuProps: {
                                                        onSubmit: () => {
                                                            shouldUpdate = true
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </>
                            )
                        }
                    } : {}
                },
                componentProps: {
                    pages,
                    showAuthor: () => false,
                    getPageName: (props) => {
                        const r = defaultGetPageName({
                            ...props, user: {
                                ...props.user ? props.user : {},
                                _status_isFeatured: props.user?._status_isFeatured || props.user?._isEditor
                            }
                        })
                        if (r === 'edit' && pageType === 'activate') {
                            return 'activate'
                        }
                        return r
                    },
                    getProps: ({ pageName, post }) => {
                        if (pageName === 'content') {
                            return {
                                ...defaultComponentProps
                            }
                        }
                        if (pageName === 'new') {
                            return {
                                pageProps: {
                                    requestProps: {
                                        redirect: routes[name + 'Route'] + '/:_id/edit/step2',
                                        go: null
                                    },
                                    //successMessage: null,
                                    SubmitProps: {
                                        label: appContext.labels[name + 'NewNextToStep2Label']
                                    }
                                }
                            }
                        }
                        if (pageName === 'edit' && (pageType !== 'step1' && pageType !== 'step2' && pageType !== 'step3' && pageType !== 'activate')) {
                            return {
                                pageProps: {
                                    requestName: name + 'SaveStep1',
                                    requestProps: {
                                        redirect: routes[name + 'Route'] + '/:_id/edit/step2',
                                        go: null
                                    },
                                    //successMessage: null,
                                    SubmitProps: {
                                        label: post?._status_isNotDeleted ? appContext.labels[name + 'EditNextToStep2Label'] : appContext.labels['restore' + N + 'SubmitLabel']
                                    }
                                }
                            }
                        }
                        if (pageName === 'edit' && pageType === 'step1') {
                            return {
                                pageProps: {
                                    requestName: name + 'SaveStep1',
                                    requestProps: {
                                        redirect: routes[name + 'Route'] + '/:_id/edit/step2',
                                        go: null
                                    },
                                    //successMessage: null,
                                    SubmitProps: {
                                        label: post?._status_isNotDeleted ? appContext.labels[name + 'EditNextToStep2Label'] : appContext.labels['restore' + N + 'SubmitLabel']
                                    }
                                }
                            }
                        }
                        if (pageName === 'edit' && pageType === 'step2') {
                            return {
                                pageProps: {
                                    requestName: name + 'SaveStep2',
                                    requestProps: {
                                        redirect: routes[name + 'Route'] + '/:_id/edit/step3',
                                        go: null
                                    },
                                    //successMessage: null,
                                    SubmitProps: {
                                        label: post?._status_isNotDeleted ? appContext.labels[name + 'EditNextToStep3Label'] : appContext.labels['restore' + N + 'SubmitLabel']
                                    },
                                    PrevProps: {
                                        href: routes[name + 'Route'] + '/' + post._id + '/edit/step1'
                                    }
                                }
                            }
                        }
                        if (pageName === 'edit' && pageType === 'step3') {
                            return {
                                pageProps: {
                                    requestName: name + 'SaveStep3',
                                    requestProps: {
                                        redirect: !post?._requiredData ? routes[name + 'Route'] + '/' + post._id + '/edit/step2' : routes[name + 'Route'] + '/:_id/edit/activate',
                                        go: null
                                    },
                                    //successMessage: null,
                                    SubmitProps: {
                                        label: post?._status_isNotDeleted ? appContext.labels[name + 'EditNextToActivateLabel'] : appContext.labels['restore' + N + 'SubmitLabel']
                                    },
                                    PrevProps: {
                                        href: routes[name + 'Route'] + '/' + post._id + '/edit/step2'
                                    }
                                }
                            }
                        }
                        if ((pageName === 'edit' || pageName === 'activate') && pageType === 'activate') {
                            return {
                                getTitle: () => {
                                    return appContext.titles['activate' + N + 'Title']
                                },
                                pageProps: {
                                    PrevProps: {
                                        href: routes[name + 'Route'] + '/' + post._id + '/edit/step3'
                                    }
                                }
                            }
                        }
                        return {}
                    },
                    getMenuContext,
                    getPostContext
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, appContext, postContext } = p

        const n = 'job'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'cardList',
                disablePageInfo: true,
                getMenu: (props) => {
                    const menu = defaultGetMenu(props)
                    return [
                        {
                            label: () => {
                                return appContext.menus[n + 'ListActionRequiredData']
                            },
                            role: (p) => {
                                const isAdmin = p.user && p.user._status_isFeatured && !p.user._isEditor
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return !p.post?._requiredData && p.post?._jobStatus < 0 && (isAdmin || isAuthor)
                            },
                            featured: true,
                            disableParentRoute: true,
                            href: ({ post }) => {
                                return appContext.routes[n + 'Route'] + '/' + post._id + '/edit/step2'
                            },
                            featuredMenuItemProps: {
                                Component: Button,
                                variant: 'contained',
                                size: 'default',
                                color: 'primary',
                                style: {
                                    alignSelf: 'center'
                                }
                            }
                        },
                        {
                            label: (p) => {
                                return p.post?._jobStatus === 0 ? appContext.menus[n + 'ListActionReActivate'] : appContext.menus[n + 'ListActionActivate']
                            },
                            role: (p) => {
                                const isAdmin = p.user && p.user._status_isFeatured && !p.user._isEditor
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return p.post?._requiredData && p.post?._jobStatus <= 0 && (isAdmin || isAuthor)
                            },
                            featured: true,
                            disableParentRoute: true,
                            href: ({ post }) => {
                                return appContext.routes[n + 'Route'] + '/' + post._id + '/edit/activate'
                            },
                            featuredMenuItemProps: {
                                Component: Button,
                                variant: 'contained',
                                size: 'default',
                                color: 'primary',
                                style: {
                                    alignSelf: 'center'
                                }
                            }
                        },
                        {
                            label: appContext.menus[n + 'ListActionEditFeatured'],
                            role: (p) => {
                                const isAdmin = p.user && p.user._status_isFeatured && !p.user._isEditor
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                const isFeatured = p.post?._isFeatured
                                return isFeatured && p.post?._jobStatus > 0 && (isAdmin || isAuthor)
                            },
                            featured: true,
                            startIcon: <StarIcon />,
                            disableParentRoute: true,
                            href: ({ post }) => {
                                return appContext.routes[n + 'Route'] + '/' + post._id + '/edit/activate'
                            },
                            featuredMenuItemProps: {
                                Component: Button,
                                variant: 'outlined',
                                size: 'default',
                                color: 'featured',
                                style: {
                                    alignSelf: 'center'
                                }
                            }
                        },
                        {
                            label: appContext.menus[n + 'ListActionAddFeatured'],
                            role: (p) => {
                                const isAdmin = p.user && p.user._status_isFeatured && !p.user._isEditor
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                const isFeatured = p.post?._isFeatured
                                return !isFeatured && p.post?._jobStatus > 0 && (isAdmin || isAuthor)
                            },
                            featured: true,
                            startIcon: <StarIcon />,
                            disableParentRoute: true,
                            href: ({ post }) => {
                                return appContext.routes[n + 'Route'] + '/' + post._id + '/edit/activate'
                            },
                            featuredMenuItemProps: {
                                Component: Button,
                                variant: 'outlined',
                                size: 'default',
                                color: 'featured',
                                style: {
                                    alignSelf: 'center'
                                }
                            }
                        },
                        ...menu
                    ]
                },
                beforeSetUser: function beforeSetUser(user) {

                    if (!user) {
                        return null
                    }

                    return {
                        ...user,
                        _status_isFeatured: user?._status_isFeatured || user?._isEditor
                    }
                },
                ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext /*statusManager*/ } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                /*{
                    label: menus[postContext.name + Ns + 'Menu'],
                    href: function(p) {
                        return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                    },
                    role: function(p) {
                        const isAdmin = p.user && p.user._status_isFeatured
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (!isAdmin && !isAuthor) && (p.post?.activeJobs || p.post?._author?.activeJobs)
                    },
                    startIcon: <JobIcon />,
                    order: 20
                },*/
                {
                    divider: true,
                    label: 'Munkáltatók',
                    LabelProps: {
                        style: {
                            marginTop: '0.5em'
                        }
                    },
                    style: {
                        marginBottom: '0.5em'
                    },
                    role: function(p) {
                        const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAdmin || isAuthor)
                    }
                },
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                    },
                    role: function(p) {
                        const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAdmin || isAuthor)
                    },
                    startIcon: <JobIcon />,
                    order: 21,
                    items: [
                        {
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(p) {
                                return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            },
                            disableParentRoute: true
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                            }
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAdmin || isAuthor)
                            }
                        },
                        {
                            label: function() {
                                return menus[postContext.name + Ns + 'InactiveMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/inactive' : routes[postContext.name + Ns + 'Route'] + '/inactive'
                            },
                            role: function(p) {
                                const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAdmin || isAuthor)
                            }
                        },
                        {
                            label: function() {
                                return menus[postContext.name + Ns + 'DraftsMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/drafts' : routes[postContext.name + Ns + 'Route'] + '/drafts'
                            },
                            role: function(p) {
                                const isAdmin = ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAdmin || isAuthor)
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                return ((p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor))
                            }
                        },
                        {
                            label: function() {
                                return menus['banned' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/banned' : routes[postContext.name + Ns + 'Route'] + '/banned'
                            },
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        }
                    ]
                }
            ]
        }

        function getFeaturedActions(props) {

            const { appContext } = props

            return [
                {
                    label: appContext.menus['new' + N + 'Menu'],
                    href: appContext.routes[n + 'Route'] + '/new',
                    role: function(p) {
                        return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id)) && !p.page
                    },
                    startIcon: <AddCircleIcon />,
                    disableParentRoute: true,
                    featured: true,
                    featuredMenuItemProps: {
                        color: 'secondary',
                        variant: 'contained',
                        Component: Button,
                        size: 'default'
                    }
                }
            ]
        }

        const parentRoute = appContext.routes[postContext.name + 'Route']

        function addContentMenuItems() {
            return [
                {
                    role: function(p) {
                        const { user, post } = p
                        const isAdmin = user && user._status_isFeatured
                        const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
                        return !isAdmin && !isAuthor && (post?.activeJobs)
                    },
                    order: 20,
                    Component: () => {

                        const postContext = useContext(PostContext)

                        const { user, post } = postContext

                        const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id))

                        return (
                            <Posts
                                name={n}
                                type={'cardList'}
                                label={isAuthor ? appContext.titles['my' + Ns + 'Title'] : appContext.titles['user' + Ns + 'Title']}
                                nextLabel={isAuthor ? appContext.labels['moreFromMy' + Ns + 'Title'] : appContext.labels['moreFromUser' + Ns + 'Title']}
                                LabelProps={{
                                    variant: 'h5',
                                    weight: 700
                                }}
                                requestParams={{
                                    page: 1,
                                    perPage: 5,
                                    _author: post?._id,
                                    filter: {
                                        _operators: {
                                            _jobStatus: {
                                                gt: 0
                                            }
                                        }
                                    }
                                }}
                                posts={[]}
                                postsSlice={[]}
                                fromRequest={true}
                                enableCache={false}
                                carousel={false}
                                NextProps={{
                                    href: parentRoute + ((post?._id) ? '/' + post._id + appContext.routes[postContext.name + Ns + 'Route'] : appContext.routes[postContext.name + Ns + 'Route']),
                                    color: undefined,
                                    size: 'default',
                                    variant: 'text'
                                }}
                                NextButtonContainerProps={{
                                    style: {
                                        justifyContent: 'flex-end'
                                    }
                                }}
                                nextButtonShowsOnlyIfCountGreaterThanLength={true}
                                disableMenu={false}
                                disableAvatars={true}
                            />
                        )
                    }
                }
            ]
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = ((user && user._status_isFeatured) || (user && user._isEditor))
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)
            //const authorIsAdmin = post && post._status_isFeatured

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'drafts' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'inactive' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? page : null

        }

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = ((user && user._status_isFeatured) || (user && user._isEditor))
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            if (page === ns && pageType === 'drafts' && isAuthorOrAdmin) {
                return appContext.titles[postContext.name + Ns + 'DraftsTitle']
            }

            if (page === ns && pageType === 'inactive' && isAuthorOrAdmin) {
                return appContext.titles[postContext.name + Ns + 'InactiveTitle']
            }

            return r.getDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName,
            getDashBoardTitle,
            getFeaturedActions
        }
    },
    adminMenu: function(p) {

        const name = 'job'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 20

        const { routes } = p.appContext

        defaultAdminMenu.items = defaultAdminMenu.items.filter((item) => {
            return item.href !== routes[name + 'Route'] + '/new'
        })

        defaultAdminMenu.items.splice(1, 0, ...[{
            label: p.appContext.menus[ns + 'LowView'],
            href: routes[ns + 'Route'] + '/lowview'
        }])

        const draftsAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name: 'draft' })
        draftsAdminMenu.items.splice(1, 1)
        draftsAdminMenu.order = 21

        return [defaultAdminMenu, draftsAdminMenu]
    },
    editorMenu: function(p) {
        const name = 'job'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 20

        const { routes } = p.appContext

        defaultAdminMenu.items = defaultAdminMenu.items.filter((item) => {
            return item.href !== routes[name + 'Route'] + '/new'
        })

        const draftsAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name: 'draft' })
        draftsAdminMenu.items.splice(1, 1)
        draftsAdminMenu.order = 21

        return [defaultAdminMenu, draftsAdminMenu]
    }
}

export default postTypeConfig
