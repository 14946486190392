import App from './components/App'
import titles from './config/constants/titles'
import routes from './config/constants/routes'
import capitalize from './utils/capitalize'
import { runPostTypesConfigSync } from './postTypes'
import { getTitle as getAccountTitle } from './components/Account/utils'

export default function setContents(p = {}) {

    const { wapp } = p

    function getTitle({ wapp, res, title = '' }) {
        const config = wapp.getTargetObject().config
        const { siteName = 'Wapplr' } = config
        const { statusCode, statusMessage, errorMessage } = res.wappResponse
        if (statusCode === 404) {
            title = titles.notFoundTitle || statusMessage || 'Not Found'
        }
        if (statusCode === 500) {
            title = errorMessage || statusMessage || 'Internal Server Error'
        }
        return title + ' | ' + siteName
    }

    /*contents for home and some static page*/

    const home = {
        contentName: 'home',
        action: async function action(p) {
            const { wapp, req, res, ...rest } = p
            const r = (rest.status === 404) ? { ...rest } : await wapp.router.routeManager.resolve({
                path: '/page/landing-1',
                req,
                res
            })
            r.status = rest.status
            return r
        }
    }

    wapp.contents.add({
        'home': {
            render: App,
            renderType: 'react',
            title: function(p) {
                return getTitle({ ...p, title: titles.homeTitle })
            }
        }
    })

    wapp.router.replace([
        { path: '/', ...home }
    ])

    wapp.router.replace([
        { path: '/offline', ...home }
    ])

    /*contents for post types, post, document, user...*/

    runPostTypesConfigSync({ action: 'setContents', p: { wapp, routes, titles, getTitle } })

    /*contents for user account*/

    wapp.contents.add({
        account: {
            render: App,
            renderType: 'react',
            title: function(p) {

                const wappResponse = p.res.wappResponse
                const wappRequest = p.req.wappRequest
                const route = wappResponse.route
                const { params } = route

                const title = getAccountTitle({ user: wappRequest.user, page: params.page, titles })

                return getTitle({ ...p, title })
            }
        }
    })

    wapp.router.add([
        { path: routes.accountRoute, contentName: 'account' },
        { path: routes.accountRoute + '/:page', contentName: 'account' },
        { path: routes.accountRoute + '/*', contentName: 'account' }
    ])

    /*contents for stripe payment*/

    wapp.contents.add({
        stripe: {
            render: App,
            renderType: 'react',
            title: function() {
                return titles['paymentStripePaymentTitle']
            }
        }
    })

    wapp.router.add([
        { path: routes.payRoute, contentName: 'stripe' },
        { path: routes.payRoute + '/*', contentName: 'stripe' }
    ])

    wapp.router.add([
        { path: '/:slug/*', contentName: 'page' }
    ])

}

export async function contentsMiddleware(req) {

    const wapp = req.wapp

    if (!wapp.contents.contentManager.firstRequest) {
        wapp.contents.contentManager.firstRequest = async function({ req, res }) {

            if ((wapp.target === 'web' || res.wappResponse.route.contentName === 'jobs' || req.wappRequest.path === '/') && req.wappRequest.method === 'GET') {

                async function getCategories(name) {

                    const n = name
                    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
                    const Ns = capitalize(ns)

                    const categories = res.wappResponse.store.getState('res.responses.' + ns)

                    if (!categories) {

                        const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager
                        const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: n }).statusManager

                        const eliminateTime = (1000 * 60 * 60)
                        const uptoDateFromCache = (wapp.cachedResponses && wapp.cachedResponses[ns] && (Date.now() < wapp.cachedResponses[ns].date + eliminateTime))

                        const response = uptoDateFromCache && wapp.target === 'node' && (wapp.cachedResponses && wapp.cachedResponses[ns]) ? wapp.cachedResponses[ns] : await wapp.requests.send({
                            requestName: n + 'FindMany',
                            args: {
                                filter: {
                                    _operators: {
                                        _status: { gt: statusManager.getMinStatus() - 1 },
                                        _author_status: {
                                            gt: userStatusManager.getMinStatus() - 1
                                        }
                                    }
                                },
                                sort: 'ORDER_ASC',
                                perPage: 500
                            },
                            req,
                            res
                        })

                        if (response && response[n + 'FindMany']) {

                            res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                                type: 'INS_RES',
                                name: 'responses',
                                value: { [ns]: response[n + 'FindMany'], [n + 'FindMany']: null }
                            }))

                            if (wapp.target === 'node') {

                                if (!wapp.cachedResponses) {
                                    wapp.cachedResponses = {}
                                }
                                wapp.cachedResponses[ns] = {
                                    date: Date.now(),
                                    [n + 'FindMany']: {
                                        ...response[n + 'FindMany'],
                                        items: [
                                            ...response[n + 'FindMany'].items.map((post) => {

                                                const {
                                                    _author,
                                                    ...item
                                                } = post

                                                const parents = item['parent' + Ns]

                                                return {
                                                    ...item,
                                                    ...parents?.length ? {
                                                        ['parent' + Ns]: parents.map(({ _author, ...item }) => {
                                                            return item
                                                        })
                                                    } : {}
                                                }
                                            })
                                        ]
                                    }
                                }
                            }

                        }

                    }

                }

                await getCategories('profession')
                await getCategories('qualification')

                //await getCategories('softSkill')
                //await getCategories('language')

            }

            if ((wapp.target === 'web' || res.wappResponse.route.contentName === 'order' || req.wappRequest.path === '/') && req.wappRequest.method === 'GET') {

                const products = res.wappResponse.store.getState('res.responses.products')

                if (!products) {

                    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager
                    const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'product' }).statusManager

                    const response = await wapp.requests.send({
                        requestName: 'productFindMany',
                        args: {
                            filter: {
                                _operators: {
                                    _status: { gt: statusManager.getMinStatus() - 1 },
                                    _author_status: {
                                        gt: userStatusManager.getMinStatus() - 1
                                    }
                                }
                            },
                            sort: 'ORDER_ASC',
                            perPage: 96
                        },
                        req,
                        res
                    })

                    if (response && response['productFindMany']) {

                        res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                            type: 'INS_RES',
                            name: 'responses',
                            value: { 'products': response['productFindMany'], 'productFindMany': null }
                        }))

                    }

                }

            }

        }
    }

}
