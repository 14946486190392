import getDefaultMenu from '../../components/Post/menu'

function getMenu(props = {}) {
    return getDefaultMenu(props).map((menu, i) => {
        if ((i === 0)) {
            const tempRole = menu.role
            menu.role = (p) => {
                const isAdmin = p.user?._status_isFeatured
                if (!isAdmin) {
                    return false
                }
                return tempRole(p)
            }
            return menu
        } else {
            return menu
        }
    }).filter((menu) => menu)
}

export default getMenu
