import { runPostTypesConfigSync } from '../../postTypes'

const titles = {
    homeTitle: 'Főoldal',
    acceptCookiesTitle: 'Adatkezelés elfogadása',
    servicesTitle: 'Tevékenységünk',
    notFoundTitle: 'Ez a tartalom nem elérhető',
    leaveWarningTitle: 'Oldal elhagyása',
    newReleaseWarningTitle: 'Az alkalmazás frissült',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'titles' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default titles
